body{
	font-family:"Century Gothic", Verdana, Arial, sans-serif;
	background:#151b35;
	color:rgba(255,255,255,0.8);
	margin:0px;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
form.normal-form{
	width:90%;
	margin:auto;
}
form.normal-form .input-row label{
	width:150px;
	display:block;
}
form.normal-form .input-row input{
	width:90%;
	padding:5px;
	margin-top:5px;
	margin-bottom:15px;
	font-size:15px;
	background:rgba(255,255,255,0.8);
}
form.normal-form .input-row input.large-input{
		font-size:26px;
		padding:10px;
}
form.normal-form .input-row select{
	width:90%;
	padding:5px;
	margin-top:5px;
	margin-bottom:15px;
	font-size:15px;
	background:rgba(255,255,255,0.8);
}
form.normal-form .input-row textarea{
	width:90%;
	padding:5px;
	margin-top:5px;
	margin-bottom:15px;
	font-size:15px;
	background:rgba(255,255,255,0.8);
}
.clear{
	clear:both;
}
.text-right{
	text-align:right;
}
.tabs .tab{
	border-left:1px solid rgba(255,255,255,0.5);
	border-top:1px solid rgba(255,255,255,0.5);
	border-right:1px solid rgba(255,255,255,0.5);
	border-bottom:1px solid rgba(255,255,255,1);
	padding:10px 20px;
	color:rgba(255,255,255,0.5);
	font-size:16px;
	display:inline-block;
	cursor:pointer;
	margin-bottom:-1px;
	margin-left:10px;
	background:rgb(28,35,63);
}
.tabs .tab:hover{
	background:rgba(0,0,0,0.5);
	border-bottom:1px solid rgb(28,35,63);
}
.tabs .tab.selected{
	border-color:rgba(255,255,255,1);
	color:rgba(255,255,255,1);
	border-top:1px solid #FFF;
	border-right:1px solid #FFF;
	border-left:1px solid #FFF;
	border-bottom:1px solid transparent;
}
.dot{
	height: 25px;
  	width: 25px;
  	background-color: #bbb;
  	border-radius: 50%;
  	display: inline-block;
}
.dot.green{
	background-color: rgb(70, 136, 37);
}
.dot.red{
	background-color: rgb(136, 37, 37);
}

.header{
	position:relative;
	min-height:80px;
	background:#151b35;
}
.header img.logo{
	max-width:200px;
	max-height:50px;
	padding:15px 10px;
}
.header .avatar{
	right:160px;
}
.header .account{
	margin:20px;
}
.header .account .content{
	padding:0px 20px;
}
.header .account .name{
	font-size:15px;
}
.header .avatar{
	width:38px;
	height:38px;
	margin-right:10px;
}
.header .navbar li .menuitem{
	padding:0px 15px;
	line-height:80px;
}
.header .navbar{
	display:inline-block;
	vertical-align:top;
	margin-top:0px;
	margin-bottom:0px;
	padding-inline-start:0px;
	-webkit-padding-start:0px;
	margin-right:0px;
	min-width:100%;
	margin-left:0px;
	letter-spacing:-4px;
	
}
.header .navbar li{
	display:inline-block;
	line-height:60px;
	
	cursor:pointer;
	color:#FFF;
	font-size:15px;
	letter-spacing:0px;
}
.header .navbar li.active{
	text-shadow:0 0 6px #FFF;
	background:rgba(0,0,0,0.5);
}
.header .navbar li .menuitem{
	padding:0px 15px;
	line-height:80px;
}
.header .navbar li:hover{
	background-color:rgba(255,255,255,0.2);
}
  .header .account{
	min-width:150px;
	right:0px;
	top:0px;
	height:80px;
	position:absolute;
	padding:0px;
	cursor:pointer;
	margin:0px;
	
}

.header .account .content{
	padding:0px 10px;
	background-color:rgba(255,255,255,0.05);
}
.header .account .content:hover{
	background:rgba(255,255,255,0.2);
}
.header .account .name{
	line-height:60px;
	display:inline-block;
	font-size:13px;
}
.header .account .submenu{
	padding:10px 20px;
	position:absolute;
	right:0px;
	top:60px;
	min-width:200px;
	display:block;
	z-index:2;
	text-align:center;
	background:#FFF;
	
}

.header .account .submenu .menu-item{
	color:#999;
	height:35px;
	cursor:pointer;
	margin-top:-10px;
	margin-left:-20px;
	margin-right:-20px;
	padding-top:15px;
}
.header .account .submenu .menu-item:hover{
	background:#DDD;
}
.obj-header{
	margin:20px 7px;
	padding:30px;
	background-color:rgba(255,255,255,0.05);
	position:relative;
}
.obj-header .label{
	font-size:15px;
	color:rgba(255,255,255,0.3);
}
.obj-header .value{
	font-size:25px;
	color:rgba(255,255,255);
}
.obj-header .value .explainer{
	font-size:16px;
	color:rgba(255,255,255,0.3);
}
.obj-header .separator{
	height:1px;
	margin:10px 0px;
	background-color:rgba(0,0,0,0.2);
}
.obj-header .status{
	position: absolute;
	bottom:10px;
	right:10px;
}
.obj-header .status .content{
	display: none;
	position: relative;
	width:200px;
}
.obj-header .status .dot{
	height: 15px;
	  width: 15px;
}
.obj-header .status:hover .content{
	display: block;
	right:20px;
	bottom:-400px;

}
table.data td .dot{
	height: 15px;
	  width: 15px;
	cursor: pointer;
}
table.data td.note{
	position: relative;
	right:5px;
}
table.data td.note .n-content{
	display:none;
}
table.data td.note:hover .n-content{
	display:block;
	position: absolute;
	min-width: 200px;
	padding:10px;
	z-index: 1;
	background-color: #151b35;
	right: 100px;
	bottom:30px;
}
  .login-button{
	border-radius:5px;
	padding:10px 20px;
	font-size:15px;
	color:#FFF;
	display:inline-block;
	background:rgba(255,255,255,0.5);
	cursor:pointer;
	font-weight:bold;
	vertical-align:top;
	margin-top:12px;
	margin-bottom:12px;
	margin-left:12px;
	font-size:12px;
}
  .logout-button-container{
	padding:20px 0px;
	text-align:right;
}
.button{
	padding:10px 20px;
	border-radius:5px;
	background:#3478ff;
	color:#FFF;
	cursor:pointer;
	margin-left:10px;
	margin-right:10px;
	text-decoration:none;
	width:50%;
}
.button:hover{
	background:#4f89fb;
}
  .main{
	background:rgb(28,35,63);
	padding:30px 5px;
	min-height:500px;
	position:relative;
	
}
.main.beta{
	background:url(./images/beta@2x.png) no-repeat right top rgb(28,35,63);
}
.main .content{
	margin-bottom:310px;
}
.modal-dialog{
	z-index: 1001;
  width:90%;
  min-height: 150px;
  /*display: none;*/
  position: absolute;
  background:rgb(28,35,63);
  border: 1px solid #999;
  border-radius: 5px;
  top: -100px;
  margin-left:-45%;
  left:50%;
	
}
.modal-dialog.login{
	top: 30%;
	bottom:inherit;
}
.modal-dialog .dialog-header{
	height:50px;
	background-color:rgba(0,0,0,0.2);
	padding-left: 30px;
	line-height: 50px;
	font-weight:bold;
	position:relative;
}

.modal-dialog .dialog-header .close{
	height:50px;
	width:50px;
	position:absolute;
	right:0px;
	top:5px;
	background:url(./module/images/close.svg) no-repeat;
	background-size:40px;
	cursor:pointer;
	opacity:0.6;
	
}

.modal-dialog .content{
	min-height:300px;
	padding:20px 20px;
	margin-bottom:80px;
}
.modal-dialog.login .content{
	min-height:0px;
	padding:20px 20px;
	margin-bottom:0px;
}
.modal-dialog .button-container{
	height:50px;
	text-align:center;
	margin-top:50px;
	position:absolute;
	bottom:0px;
	left:0px;
	right:0px;
}
.modal-dialog .button-container .button{
	border-radius:5px;
	padding:5px 30px;
	font-size:14px;
	color:#FFF;
	
	top:30px;
	background:rgba(255,255,255,0.5);
	cursor:pointer;
	margin-left:10px;
	margin-right:10px;
}
.modal-dialog .button-container .button.blue{
	background:#74b0de;
}
.form-status{
	height:120px;
	margin-bottom:20px;
	text-align:center;
}
.form-status img{
	height:100px;
	
}

.loading{
	padding:20px;
	border:1px solid rgba(255,255,255,0.5);
	text-align:center;
	width:200px;
	margin:auto;
	background-color:rgba(255,255,255,0.05);
	border-radius:5px;
	display:block;
	position:absolute;
	top:200px;
	left:50%;
	margin-left:-121px;
	z-index:2000;
}

.loading .spinner{
	animation:rotate_loader 2s cubic-bezier(0.15, 0.4, 0.24, 0.56) infinite;
	-webkit-animation:rotate_loader 2s cubic-bezier(0.15, 0.4, 0.24, 0.56) infinite;
	-moz-animation:rotate_loader 4s linear infinite;
	width:100px;
	height:100px;
}
@-moz-keyframes rotate_loader { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes rotate_loader { 100% { -webkit-transform: rotate(360deg); } }
@keyframes rotate_loader{
	 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
}
.float{
	float:left;
}
.float.two{
	width:50%;
}
.obj-value{
	padding-left: 30px;
}
.obj-value .label{
	font-size: 15px;
	color:rgba(255,255,255,0.3);
	
}
.obj-value .value{
	font-size: 25px;
	color:rgba(255,255,255,1);
}
.obj-value .value .explainer {
    font-size: 16px;
    color: rgba(255,255,255,0.3);
}
.card-layout{
	flex-wrap:wrap;
	margin:20px;
	display:flex;
	flex-direction:row;
	flex:1 1 auto;
}	
.card-layout .card{
	
	flex-basis:100%;
	max-width:100%;
	flex-grow:0;
	max-width:100%;
	
	position:relative;
	
	background-repeat: no-repeat;
	background-size: 32px;
	background-position-x: 95%;
	background-position-y: 15%;
}

.card-layout .card.clickable{
	cursor:pointer;
}
.card-layout .card.clickable .content:hover{
	margin:0px;
	padding:28px;
	background:rgba(255,255,255,0.2);
}
.card-layout .card.clickable a{
	text-decoration:none;
	color:#FFF;
}
.card-layout .card .content{
	padding:20px;
	margin:8px;
	background-color:rgba(255,255,255,0.05);
	position:relative;
}
.card-layout .card .content .card-label{
	color:rgba(255,255,255,0.3);
	text-transform: uppercase;
}

.card-layout .card .content .total{
	font-size:24px;
	
}
.card-layout .card .content .label{
	color:rgba(255,255,255,0.3);
}
.card-layout .card .content .tokenlabel{
	color:rgba(255,255,255,0.3);
	text-transform: uppercase;
}
.card-layout .card .content .explainer{
	font-size:12px;
	color:rgba(255,255,255,0.3);
}
.card-layout .card .content .separator{
	height:1px;
	margin:10px 0px;
	background-color:rgba(0,0,0,0.2);
}
.card-layout .card .content .grid2col{
	display: grid;
	  grid-template-columns: 1fr 1fr;
}
.card-layout .card .content .value-label{
	color:rgba(255,255,255,0.3);
}
.card-layout .card .content .more-circles{
	position: absolute;
	top:0px;
	right:0px;
	width: 24px;
	height:24px;
	cursor: pointer;
}
.card-layout .card .content .more-circles:hover{
	background-color: rgba(255,255,255,0.3);
}
.card-layout .card .content .more-form{
	position: absolute;
	top:30px;
	right:3px;
	width: 100px;
	z-index: 10;
	background-color:rgba(0,0,0,0.5);
	padding:10px;
	
}
.card-layout .card .content .more-form .item{
	padding:10px;
	cursor:pointer;	
}
.card-layout .card .content .more-form .item:hover{
	background-color:rgba(255,255,255,0.5);
}
.card-layout .card .content .status{
	position: absolute;
	bottom:3px;
	right:10px;
}
.card-layout .card .content .status .content{
	display: none;
	width:200px;
	background-color: #151b35;
}
.card-layout .card .content .status .dot{
	height: 15px;
	  width: 15px;
	cursor:pointer;
}
.card-layout .card .content .status:hover .content{
	display: block;
	position: absolute;
	right:20px;
	bottom:-10px;

}
.card-layout .card .chart{
	height:300px;
	overflow:hidden;
}
.text-loading{
	background-color: rgba(0,0,0,0.2);
}

.top-chart-container{
	margin:20px 8px;
	background-color:rgba(255,255,255,0.05);
	padding:20px;
}
.skeleton.text{
	width:100px;
	height:20px;
	background-color:rgba(255,255,255,0.2);
	margin:5px 5px 5px 0px;
}
.skeleton.bar-chart{
	width:100%;
	height:450px;
	background-color:rgba(255,255,255,0.2);
	margin:5px 5px 5px 0px;
}
.skeleton.text.large{
	height:30px;
}

.select-one{
	position:relative;
	font-size:12px;
	cursor:pointer;
	margin-left:10px;
	
}

.select-one .selected{
	border:1px solid rgba(255,255,255,0.8);
	border-radius:3px;
	color:rgba(255,255,255,0.8);
	padding:10px 30px 10px 15px;
	display:inline-block;
	background:url('./module/images/table_sort_desc.svg') 95% no-repeat;
	background-size:16px;
	
	
}
.select-one .select-items{
	display:none;
	
}
.select-one.open .select-items{
	display:inline-block;
	position:absolute;
	top:26px;
	right:0px;
	z-index:2;
	min-width:75px;
	
}
.select-one .select-items .item{
	background:#FFF;
	padding:10px;
	border-bottom:1px solid #DDD;
	
	color:#999;
}
.select-one .select-items .item:hover{
	background:#EFEFEF;
}
.tab-content {
	
	border: 1px solid #FFF;
	min-height: 400px;
	padding: 30px 10px;
}
.back {
    background: url(./module/images/back_arrow.svg) no-repeat;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 25px;

    top: 25px;
    left: 10px;
	margin-bottom:0px;
	margin-top:10px;
}
.back:hover{
	background:url(./module/images/back_arrow.svg) no-repeat rgba(255,255,255,0.4);
}

@media (min-width: 600px){
	.main{
		padding:30px;
	}
	.header .navbar{
		margin-right:200px;
		min-width:0px;
	}
	.header .navbar li .menuitem{
		padding:0px 20px;
		line-height:80px;
	}
	.header .navbar li{
		font-size:16px;
	}
	.header .avatar{
		right:160px;
	}
	.header .account{
		margin:20px;
	}
	.header .account .content{
		padding:0px 20px;
	}
	.header .account .name{
		font-size:15px;
	}
	table.data{
		background-color:rgba(255,255,255,0.05);
		width:100%;
	}
	table.data .left{
		text-align:left;
	}
	table.data .right{
		text-align:right;
	}
	table.data td{
		padding:10px 20px;
		border-bottom:1px solid rgba(255,255,255,0.05);
	}
	table.data th.sortable{
		cursor:pointer;
		padding-right:30px;
	}
	table.data th.sortable:hover{
		background:rgba(0,0,0,0.2);
	}
	table.data th.sortable.asc{
		background:url(./module/images/table_sort_asc.svg) 98% 50% no-repeat;
		background-size:20px;
	}
	table.data th.sortable.desc{
		background:url(./module/images/table_sort_desc.svg) 98% 50% no-repeat;
		background-size:20px;
	}
	table.data th.left{
		text-align:left;
	}
	table.data th.right{
		text-align:right;
	}
	table.data th{
		padding:10px 20px;
		border-bottom:1px solid rgba(255,255,255,0.05);
	}
	table.data tbody tr:hover{
		background:rgba(0,0,0,0.2);
	}
	table.data img.token{
		vertical-align:middle;
		width:32px;
		height:32px;
		margin-right:15px;
	}
	table.data tr.open-detailed{
		cursor:pointer;
	}
	.card-layout{
		margin:20px;
	}
	.card-layout .card{
	
		flex-basis:50%;
		max-width:50%;
		flex-grow:0;
		max-width:50%;
	
		position:relative;
	}
}
@media (min-width: 960px){
  .modal-dialog{
    width:750px;
    position: fixed;
    margin-left:-375px;
    position: fixed;
    max-height:700px;
    bottom:5%;
    top: 5%;
    
	}
	.main-chart {
		float: left;
		width: 50%;
		margin-top: 0px;
	}
	/*
	.card-layout .card{
	
		flex-basis:25%;
		max-width:25%;
		flex-grow:0;
		max-width:25%;
	
		position:relative;
	}*/
}

